import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fal, faTools } from "@fortawesome/free-brands-svg-icons"

function Repair() {
  return (
    <div className="repairMsgBox">
      <h1>Sorry, site is currently under repair :'(</h1>
      <h3>Please try again later!</h3>
      <p>
        Visit my
        <span>
          {" "}
          <a
            href="https://www.linkedin.com/in/donni-garvida/"
            rel="noopener noreferrer"
          >
            LinkedIn profile.
          </a>
        </span>
      </p>
    </div>
  )
}

export default Repair
