import React, { useRef, useState } from "react"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import ProjectImages from "../components/image-components/project-images"
import Repair from "../components/repair/Repair"
// import Cv from "../components/files/cv"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedinIn,
  faGithub,
  faCodepen,
} from "@fortawesome/free-brands-svg-icons"

import "./main.scss"

function IndexPage() {
  const [isSiteUnderRepair, setIsSiteUnderRepair] = useState(true)
  const images = ProjectImages()

  const personHeadElement = useRef(null)
  const personRightHandElement = useRef(null)
  const personHairElement = useRef(null)

  const onContactButtonHover = () => {
    personHeadElement.current.classList.add("headTilt")
  }

  const onContactButtonMouseLeave = () => {
    personHeadElement.current.classList.remove("headTilt")
  }

  const onPersonWrapperHover = () => {
    personRightHandElement.current.classList.add("raiseHand")
    personHeadElement.current.classList.add("headTurnFace")
    personHairElement.current.classList.add("hairShorten")
  }
  const onPersonWrapperMouseLeave = () => {
    personRightHandElement.current.classList.remove("raiseHand")
    personHeadElement.current.classList.remove("headTurnFace")
  }

  if (isSiteUnderRepair) {
    return <Repair />
  }

  return (
    <Layout>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap"
          rel="stylesheet"
        />
        <meta name="description" content="Personal Website" />
        <meta name="image" property="og:image" content="https://live.staticflickr.com/65535/51889205838_a62b9e51b8_z.jpg" />
        <meta name="author" content="Donni Riel Garvida" />
      </Helmet>
      <SEO title="Donni Riel Garvida" />

      <nav id="nav">
        <div className="nav_wrapper wrapper">
          <ul className="nav-initial">
            <li>
              <a href="#nav">DG</a>
            </li>
          </ul>

          <ul>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#projects">Projects</a>
            </li>
            <li>
              <a href="mailto:donni.garvida@gmail.com">Contact</a>
            </li>
          </ul>
        </div>
      </nav>

      {/*==== Hero Section ====*/}
      <section id="hero" className="wrapper">
        <div className="hero-wrapper wrapper-smaller">
          <div className="greeting">
            <div className="hero_header">
              <Fade top>
                <p>
                  Hello, my name is Donni. I am an aspiring Web Developer and a
                  Computer Science graduate living in Oslo, Norway.
                </p>
              </Fade>
            </div>
          </div>
          <div className="contact-button">
            <a
              href="mailto:donni.garvida@gmail.com"
              id="contact-top"
              onMouseEnter={onContactButtonHover}
              onMouseLeave={onContactButtonMouseLeave}
            >
              Contact Me!
            </a>
          </div>
        </div>
      </section>

      <section id="person" className="wrapper">
        <div
          className="person-wrapper"
          onMouseEnter={onPersonWrapperHover}
          onMouseLeave={onPersonWrapperMouseLeave}
        >
          <div ref={personHeadElement} className="head">
            <div ref={personHairElement} className="hair"></div>
          </div>
          <div className="person-body"></div>
          <div className="left-hand"></div>
          <div ref={personRightHandElement} className="right-hand"></div>
          <div className="legs"></div>
          <div className="legs-bent">
            <div className="legs-bent-lower"></div>
          </div>
          <div className="person-pc"></div>
        </div>
      </section>

      <section id="about" className="wrapper">
        <h2>About Me</h2>
        <div className="about-text">
          <p>
            I recently finished my bachelor study and graduated on the summer of year 2022. I have an education and work
            background in the Health Care field but pursued a career in tech due
            to my great interest in it.
          </p>
          <p>
            During the summer of 2021, I landed a tech Internship role as a
            Software Engineer at a national newspaper company{" "}
            <i>(Aftenposten)</i>. See my work experience and education
            background on{" "}
            <a
              href="https://www.linkedin.com/in/donni-garvida/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
            .{" "}
          </p>
          <p>
            Outside of school, I love playing video games and building small
            programming projects to learn and improve. Selected projects are
            shown in the section below. All projects are uploaded to my{" "}
            <a
              href="https://github.com/don-riel"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>{" "}
            page.
          </p>
        </div>
      </section>

      {/*==== Projects Section ====*/}
      <section id="projects">
        <div className="wrapper">
          <h2>Projects</h2>
          <div className="project-items-box">
            <div id="chrome-extension" className="project-item project-item-left">
              <div className="project-item-description">
                <p className="project-title">HTML Outliner | Chrome Extension</p>
                <p className="project-tech-stack">
                 HTML, CSS, JavaScript
                </p>
                <a
                  className="project-visit-link"
                  href="https://chrome.google.com/webstore/detail/html-outliner/dgkgjbfkahjimlecbaalpmpgljnabpde"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit {">"}
                </a>
              </div>
              <div className="chrome-extension-images">
                <div className="chrome-extension-image-container">
                  <Img
                    fluid={images.chrome_extension.childImageSharp.fluid}
                    className="chrome-extension-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="project-items-box">
            {/* Schoole Experiment Project */}
            <div id="school" className="project-item project-item-left">
              <div className="project-item-description">
                <p className="project-title">School Experiment Tool</p>
                <p className="project-tech-stack">
                  React, NodeJS, Express, PostgreSQL
                </p>
                <a
                  className="project-visit-link"
                  href="https://experiment-mmi.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit {">"}
                </a>
              </div>
              <div className="school-experiment-images">
                <div className="mouse">
                  <div className="mouse-division-left"></div>
                  <div className="mouse-division-right"></div>
                </div>
                <div className="pc">
                  <div className="pc-bar"></div>
                  <div className="pc-bar2"></div>
                </div>
                <div className="clock">
                  <div className="clock-hands"></div>
                </div>
              </div>
            </div>
            {/* Travel Blog Project */}
            <div id="blog" className="project-item project-item-right">
              <div className="project-item-description">
                <p className="project-title">Travel Blog - Anna Karolina</p>
                <p className="project-tech-stack">React/Gatsby, CSS Modules</p>
                <a
                  className="project-visit-link"
                  href="https://anna-karolina.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit {">"}
                </a>
              </div>
              <div className="blog-images">
                <div className="blog-mobile-image-container">
                  <Img
                    fluid={images.blog_mobile.childImageSharp.fluid}
                    className="blog-mobile-image"
                  />
                </div>
                <div className="blog-desktop-image-container">
                  <Img
                    fluid={images.blog_desktop.childImageSharp.fluid}
                    className="blog-desktop-image"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="project-items-box">
            {/*  Hangman Project */}
            <div id="hangman" className="project-item project-item-left">
              <div className="project-item-description">
                <p className="project-title">Hangman</p>
                <p className="project-tech-stack">React, SASS</p>
                <a
                  className="project-visit-link"
                  href="https://don-riel.github.io/hangman/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit {">"}
                </a>
              </div>
              <div className="hangman-image-container">
                <Img
                  fluid={images.hangman.childImageSharp.fluid}
                  className="hangman-image"
                />
              </div>
            </div>
            {/* Tower of Hanoi Project */}
            <div id="tower" className="project-item project-item-right">
              <div className="project-item-description">
                <p className="project-title">Tower of Hanoi</p>
                <p className="project-tech-stack">
                  React, HTML Drag and Drop API
                </p>
                <a
                  className="project-visit-link"
                  href="https://towerofhanoi-game.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit {">"}
                </a>
              </div>
              <div className="tower-bars">
                <div className="bars-container">
                  <div className="bar bar-top"></div>
                  <div className="bar bar-middle-1"></div>
                  <div className="bar bar-middle"></div>
                  <div className="bar bar-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*==== Contact Section ====*/}

      <section id="contact" className="wrapper">
        <div className="contact_content">
          <Fade top>
            <h2>Please feel free to contact me :)</h2>
          </Fade>

          <div className="contact-button">
            <a
              href="mailto:donni.garvida@gmail.com"
              className="button button__contact btn_margin"
            >
              Contact Me!
            </a>
          </div>

          <ul className="social_media_list">
            <li>
              <a
                href="https://www.linkedin.com/in/donni-garvida/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  aria-label="Linkedin link"
                />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/don-riel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} aria-label="Github link" />
              </a>
            </li>
            <li>
              <a
                href="https://codepen.io/don-riel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faCodepen}
                  aria-label="Codepen link"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </a>
            </li>
          </ul>
          <p>{new Date().getFullYear()} @ donni.garvida@gmail.com</p>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
